// Here you can add other styles
@import "variables";

.sidebar .nav-link,
.sidebar .nav-title {
  padding-right: 0.4rem;
  padding-left: 0.4rem;
}

.fit-to-content {
  width: -moz-fit-content; // Older Firefox version
  width: fit-content;
}

.app-header {
  background-color: $syscoBlue;
}

.app-header .navbar-toggler {
  background-color: white;
}

.app-header .nav-link {
  color: white;
}

.sidebar .nav-link {
  font-size: 91%;
}

.sidebar .nav-link.active {
  background-color: #305d90;
}

.breadcrumb .breadcrumb-item {
  background-color: white;
}

.card-header {
  background-color: $syscoBlue;
  color: white;
}

#earned-income-mappings-card-header.card-header {
  background-color: #f0f3f5;
  color: black;
  padding: 3px 5px;
  font-weight: bold;
}

.ReactTable .rt-resizable-header {
  height: 30px;
}

.dataTables_paginate {
  margin-top: 20px;
}

.dataTables_info {
  margin-top: 20px;
}

.ReactTable .rt-tbody .rt-td {
  padding-bottom: 2px;
  padding-top: 2px;
}

.ReactTable .rt-td {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.ReactTable .-pagination .-btn {
  background-color: #bec3c6;
  color: black;
}

.ReactTable .-pagination .-previous,
.ReactTable .-pagination .-next {
  background-color: #e6eaed;
}

.ReactTable .-pagination .-btn[disabled] {
  opacity: 0.3 !important;
}

.ReactTable .rt-resizable-header-content {
  color: black;
  font-weight: 550;
}

.divider {
  height: 1px;
  background-color: #bec3c6;
}

input.date-picker {
  &:not(:placeholder-shown) {
    &:not(:focus) {
      &:invalid {
        border: 2px solid #f86c6b !important;
      }
    }
  }
}

.custom-input-button {
  height: 32px;
  padding: 0 0.6rem;
}

.custom-control-label::before,
.custom-control-label::after {
  width: 1rem;
  height: 1rem;
}

.custom-control-input:checked ~ .custom-control-label::before {
  background-color: #196b88;
}

.nav-dropdown-items {
  font-size: 13px;
}

.sidebar .nav-dropdown.open {
  background-color: #1b1e1f;
}

.sidebar .nav-dropdown-toggle::before {
  right: 0.6rem;
  transform: rotate(-90deg);
}

.sidebar .nav-dropdown.open > .nav-dropdown-toggle::before {
  transform: rotate(90deg);
}

.custom-multi-select-checkbox {
  color: black;
  float: right;
  border-radius: 0.25rem;
}

.picky__input {
  background-color: #20a8d8;
}

#picky__button__button {
  background-color: #20a8d8;
  border: 0px none;
  color: white;
  font-size: 0.875rem;
}

#picky__button__button > span {
  color: white;
  font-size: 0.875rem;
}

.picky__placeholder {
  background-color: #20a8d8;
  color: white;
}

.background-transparent {
  background-color: transparent;
}

#user_profile_vendors_table_div {
  div[role="rowgroup"] .rt-td {
    font-weight: bold;
    color: #555;
  }

  div[role="rowgroup"] > div[role="rowgroup"] .rt-td {
    font-weight: normal;
  }

  div[role="rowgroup"] > div[role="rowgroup"] {
    .rt-td {
      &:nth-child(2),
      &:nth-child(3) {
        padding-left: 15px;
      }
    }
  }
}

.toggle-selector {
  display: flex;
  flex: 0 0 auto;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;

  .toggle-label {
    font-weight: 500;
    margin-left: 5px;
  }
}

.userProfileModalBody {
  position: relative;
  padding: 0;
  overflow-x: hidden;
  overflow-y: auto;
  height: 93vh;

  .closebtn {
    position: absolute;
    right: 30px;
    top: 18px;
    color: black;
    opacity: 0.9;
    font-size: 20px;
    z-index: 1;
    cursor: pointer;
  }
}

.ReactTable .-pagination .-btn {
  background-color: #bec3c6 !important;
}

.card,
.card-header,
.tab-content,
.nav,
#app_header_user_group_selection_drop_down {
  box-shadow: 0 1px 0px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.12);
}

.card-body {
  padding: 1rem;
}

.card {
  margin-bottom: 0;
}

.modal-open {
  overflow-y: auto;
}

.modal-header .close {
  &:focus {
    outline: none;
    box-shadow: none;
  }
}

.reporting-filter-addon {
  width: 20%;
}

.input-group-addon .input-group-text {
  min-width: 140px;
  padding: 0.375rem 0.5rem;
}

.input-group-element {
  width: calc(100% - 145px);
}

.input-group-element-search {
  width: calc(100% - 111px);
}

.cost-driver-input-group-element {
  width: calc(100% - 125px);
  max-height: 35px;
}

.css-1wa3eu0-placeholder {
  color: black !important;
}

.toggle-columns-button:hover {
  background-color: lightskyblue;
}

.custom-dashboard-button {
  color: white !important;
  display: inline-block;
  text-align: center;
  &:hover {
    opacity: 0.8;
    box-shadow: 0 3px 5px #aaacaa;
  }
  &:focus {
    outline: none;
    box-shadow: none;
  }
}

.dashboard-panel-title {
  color: #585858;
  padding: 1rem 1.2rem 0rem;
  margin: 0rem;
  font-weight: bold;
  font-size: 13.5px;
}

textarea {
  width: 100%;
  max-width: 100;
  min-height: 5rem;
  border-radius: 4px;
  resize: none;
  padding: 10px;
  border: 1px solid lightgray;
  &:focus {
    outline: none !important;
    border: 2px solid;
    border-color: #2684ff;
  }
}

.functional-tile-tooltip {
  padding: 6px 10px !important;
  border-radius: 4px !important;
}

.file-dropzone-with-deletion {
  cursor: pointer;
  height: 60px;
  background-color: lightgray;
  padding: 0.5rem;
  margin-bottom: 5px;
  border: 2px dashed grey;
  border-radius: 4px;
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
  &:focus {
    outline: none;
    box-shadow: none;
  }
}

.disabled-file-dropzone-with-deletion {
  border: none;
  display: flex;
  &:focus {
    outline: none;
    box-shadow: none;
  }
}

.remove-file-icon {
  cursor: pointer;
  position: absolute;
  right: 0%;
  padding-right: 0.5rem;
  padding-top: 3px;
  font-size: 12px;
  color: gray;
}

.list-items li {
  padding: 5px;
  background-color: rgba(211, 211, 211, 0.219);
  border: 1px solid lightgray;
  margin-top: 5px;
  border-radius: 2px;
  font-size: 12px;
}

.quick-search-box .form-control {
  padding-left: 2.375rem;
  border: 1px solid lightgray;
  &:focus {
    outline: none !important;
    border: 2px solid;
    border-color: #2684ff;
    box-shadow: none;
  }
}

.quick-search-box .quick-search-icon {
  position: absolute;
  display: block;
  padding: 10px 15px;
  text-align: center;
  pointer-events: none;
  color: lightgray;
}

.search-results-list:hover {
  cursor: pointer;
  text-decoration: underline;
}

.refresh-button {
  cursor: pointer;
  margin-left: 5px;
  font-size: 15px;
  color: gray;
  border-radius: 100%;
  border: none;
  width: 30px;
  height: 30px;
  background-color: rgba(211, 211, 211, 0.651);
  &:hover {
    box-shadow: 0 2px 3px #aaacaa;
  }
  &:focus {
    outline: none;
    box-shadow: none;
  }
}

.content-wrapper > * {
  margin-bottom: 0.5rem;
}

.request-message {
  margin-bottom: 0.4rem;
}

.request-message:first-of-type {
  margin-bottom: 0;
}

.item-input {
  border: none;
  width: 10rem;
  line-height: 1;
  height: 1.3rem;
  &:focus {
    box-shadow: none;
  }
}

.info-icon {
  &:hover {
    cursor: pointer;
  }
}

.ag-grid-edited-row {
  background-color: #baf2af !important;
}

.ag-grid-pending-edit-row {
  background-color: #fef0ab !important;
}

.ag-grid-approved-row {
  background-color: #b5e9dc !important;
}

.ag-grid-declined-row {
  background-color: #ffbebe !important;
}

.ag-grid-medium-variance-row {
  background-color: #BBDEFB !important;
}

.ag-grid-high-variance-row {
  background-color: #FFE0B2 !important;
}

.ag-grid-column-header {
  font-size: 13px;
  font-weight: bold;
  color: #757070 !important;
}

.back-button {
  padding: 0.5rem 0;
  color: #20a8d8 !important;
  border: none;
  &:focus {
    outline: none;
    box-shadow: none;
  }
  &:hover {
    color: #167495 !important;
    text-decoration: underline;
  }
}

.upload-file-button {
  display: inline-block;
}

.upload-file-button input[type=file] {
  display:none;
}

.progress-bar {
  transition: width 0.3s ease;
}

.ellipsis {
  overflow: hidden;
  display: inline-block;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
}

.quick-links-panel {
  padding: 0.7rem 0.0rem 0rem;
}

@media only screen and (max-width: 1200px) {
  .quick-links-panel {
    padding: 0.7rem 0.7rem 0rem;
  }
}

.workspace-search-span-dd .dropdown-item:focus {
  outline-color: #157396;
}

.workspace-search-custom-input:focus {
  border-color: #007bff !important;
  box-shadow: 0 0 0 1px #007bff;
}

.workspace-search-panel-cancel-button {
  padding-left: 30px;
  padding-right: 7.5px;
}

.workspace-search-panel-submit-button {
  padding-left: 7.5px;
}

.workspace-search-date-label {
  color: #5c6873;
  margin: 0px 1px 8px 1px;
  font-weight: 550;
}

.workspace-search-advanced-search-section-container {
  margin: 0px;
  display: flex;
  justify-content: end;
}

.workspace-search-notification-error-message {
  margin-bottom: 0rem;
  font-size: 10px;
  color: red;
}

.workspace-search-notification-icon {
  font-size: 12px;
  color: red;
  padding: 5px;
}

.workspace-search-notification-container {
  float: right;
  margin-right: 0.2rem;
}

.cost-driver-custom-scrollbar {
  scrollbar-width: thin;
  scrollbar-color: #888 #f5f5f5;
}

.cost-driver-custom-scrollbar::-webkit-scrollbar {
  width: 8px;
}

.cost-driver-custom-scrollbar::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 4px;
}

.cost-driver-custom-scrollbar::-webkit-scrollbar-thumb:hover {
  background-color: #555;
}
